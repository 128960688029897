<template>
  <v-btn class="text-none text-body-1 font-weight-medium"
         color="secondary"
         depressed
         large
         rounded>
    Registrate Gratis
  </v-btn>
</template>

<script>
export default {
  name: "RegisterHome",
}
</script>

<style scoped>
</style>
